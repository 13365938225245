import React, { useEffect, useRef } from "react"
import { noop } from "lodash"
import cs from "classnames"
import Icon from "@cloudbreakus/cloudbreak-react-icon"

const getProps = (focus, small) => ({
  audio: {
    icon: "phoneIn",
    iconClass: small ? "fill-bblue-500" : "fill-green-500",
    text: "ANSWER",
    textClass: small ? "text-bblue-500" : "text-green-500",
    wrapperClass: cs(
      small
        ? ["ring-bblue-500 group-hover:bg-bblue-200", { "group-focus:bg-bblue-200": !focus }]
        : ["ring-green-500 group-hover:bg-green-200", { "group-focus:bg-green-200": !focus }]
    ),
  },
  video: {
    icon: "IoVideocamOutline",
    iconClass: small ? "stroke-bblue-500" : "stroke-green-500",
    text: "ANSWER",
    textClass: small ? "text-bblue-500" : "text-green-500",
    wrapperClass: cs(
      small
        ? ["ring-bblue-500 group-hover:bg-bblue-200", { "group-focus:bg-bblue-200": !focus }]
        : ["ring-green-500 group-hover:bg-green-200", { "group-focus:bg-green-200": !focus }]
    ),
  },
  listen: {
    icon: "headphones",
    iconClass: small ? "stroke-morange-500" : "stroke-red-500",
    text: "LISTEN IN",
    textClass: small ? "text-morange-500" : "text-red-500",
    wrapperClass: cs(
      small
        ? ["ring-morange-500 group-hover:bg-morange-200", { "group-focus:bg-morange-200": !focus }]
        : ["ring-red-500 group-hover:bg-red-200", { "group-focus:bg-red-200": !focus }]
    ),
  },
})

const AnswerButton = ({ children, channel, onClick, focus = false, small = false, disabled, id, tooltipContent }) => {
  const alertRef = useRef(null)
  useEffect(() => {
    if (alertRef.current && focus) {
      alertRef.current.focus()
      alertRef.current.tabIndex = "0"
    }
  }, [focus])

  const prop = getProps(focus, small)[channel?.toLowerCase()] || getProps(focus, small)["audio"]
  const iconSize = small ? 20 : 32
  return (
    <button
      onClick={disabled ? noop : onClick}
      ref={alertRef}
      className="group focus:outline-none"
      data-tooltip-id={id}
      data-tooltip-content={tooltipContent}
      data-testid={id}
      disabled={disabled}
    >
      <div
        className={cs(
          "flex justify-center items-center rounded-full mx-auto",
          small ? "ring-[3px] h-6 w-6" : "ring-2 h-14 w-14",
          prop.wrapperClass
        )}
      >
        <Icon icon={prop.icon} className={prop.iconClass} size={iconSize} />
      </div>
      <span className={cs("font-bold", prop.textClass)}>{children || prop.text}</span>
    </button>
  )
}

export default AnswerButton
