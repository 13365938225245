import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { ErrorBoundary, LoadingProvider } from "@cloudbreakus/ui-components"
import { AccessibilityProvider } from "contexts/Accessibility"
import { AuthProvider } from "contexts/Auth"
import { StorageProvider } from "contexts/Storage"
import { ProfileProvider } from "contexts/Profile"
import { PresenceProvider } from "contexts/Presence"
import { LanguagesProvider } from "contexts/Languages"
import { QueuesProvider } from "contexts/Queues"
import { CallProvider } from "contexts/Call"
import { CallHistoryProvider } from "contexts/CallHistory"
import { ScriptsProvider } from "contexts/Scripts"
import MainRouter from "navigation/MainRouter"
import Security from "contexts/Auth/Security"
import { AlertsProvider } from "contexts/Alerts"

import { FeatureBangProvider } from "@cloudbreakus/featurebang-react"

import Versioning from "containers/Versioning"
import FeatureFlaggingConfig from "containers/FeatureFlaggingConfig"
import { config } from "utils/configService"
import { logger } from "utils/logger"
import CallSupportWindow from "screens/CallSupportWindow"
import ErrorPage from "components/ErrorPage"
import { useServices } from "./useServices"

const MainMVCCApp = () => (
  <div data-testid={"app-base"} className={"text-center"}>
    <Security>
      <AuthProvider>
        <StorageProvider>
          <ProfileProvider>
            <PresenceProvider>
              <LanguagesProvider>
                <QueuesProvider>
                  <CallProvider>
                    <CallHistoryProvider>
                      <ScriptsProvider>
                        <Versioning>
                          <FeatureFlaggingConfig>
                            <MainRouter />
                          </FeatureFlaggingConfig>
                        </Versioning>
                      </ScriptsProvider>
                    </CallHistoryProvider>
                  </CallProvider>
                </QueuesProvider>
              </LanguagesProvider>
            </PresenceProvider>
          </ProfileProvider>
        </StorageProvider>
      </AuthProvider>
    </Security>
  </div>
)

const App = () => {
  useServices()

  return (
    <BrowserRouter>
      <FeatureBangProvider config={config}>
        <ErrorBoundary showScreen logger={logger} ErrorPage={ErrorPage}>
          <LoadingProvider>
            <AlertsProvider logger={logger}>
              <AccessibilityProvider>
                <Routes>
                  <Route path="/call-support-window" element={<CallSupportWindow />} />
                  <Route path="*" element={<MainMVCCApp />} />
                </Routes>
              </AccessibilityProvider>
            </AlertsProvider>
          </LoadingProvider>
        </ErrorBoundary>
      </FeatureBangProvider>
    </BrowserRouter>
  )
}

export default App
