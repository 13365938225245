import React, { createContext, memo, useState, useCallback, useEffect, useMemo } from "react"
import { useLocation } from "react-router-dom"
import shallow from "zustand/shallow"
import { SSE } from "utils/SSE"
import { createContextHook } from "utils/contextHelpers"
import { useOktaAuth } from "@okta/okta-react"
import { dataService } from "utils/dataService"
import { config } from "utils/configService"
import { useLoading } from "@cloudbreakus/ui-components"
import { useAlertMessage } from "hooks/useAlertMessage"
import { useHeartbeat } from "./useHeartbeat"
import { useLogAttributes } from "./useLogAttributes"
import { useOkta } from "./useOkta"

import { logger } from "utils/logger"
import useStore from "store"
import { selectBasicAuthenticated, selectIsAuthenticated, selectToken, selectUser } from "store/selectors/auth"
import { usePureCallback } from "hooks/usePureCallback"
import { useNavigate } from "react-router-dom"
import { useConfigFlag } from "@cloudbreakus/featurebang-react"
import { authService } from "utils/authService"
import { AUTH_FAILURE, AUTH_SUCCESS, AUTH_DATA_REFRESHED, OktaAppError } from "@cloudbreakus/authentication-service"

const initialState = {
  SSEdied: false,
  SSEalive: false,
  token: null,
  userData: null,
  error: null,
}

const AuthContext = createContext()

const _logout = async () => {
  try {
    await dataService.Auth.logout()
  } catch (err) {
    logger?.error(
      "An error occurred during API logout - Ignored ",
      `status: ${err?.status} - message: ${err?.message}`,
      err?.base ?? err
    )
  }
}

const _AuthProvider = ({ children }) => {
  const [SSEopen, setSSEopen] = useState(initialState.SSEalive)
  const [SSEConnected, setSSEConnected] = useState(false)
  const [SSEdied, setSSEdied] = useState(initialState.SSEdied)
  const [requestResponse, setRequestResponse] = useState({})
  const [hasOptedInMFA, setHasOptedInMFA] = useState(false)

  const [isAuthenticated, setIsAuthenticated] = useStore(selectIsAuthenticated, shallow)
  const [basicAuthenticated, setBasicAuthenticated] = useStore(selectBasicAuthenticated, shallow)
  const [token, setToken] = useStore(selectToken, shallow)
  const [userData, setUserData] = useStore(selectUser, shallow)
  const { showLoading, hideLoading } = useLoading()
  const { oktaAuth } = useOktaAuth()
  const { showErrorToast, showInfoToast } = useAlertMessage()
  const navigate = useNavigate()
  const { state } = useLocation()
  const userIdIsSet = useConfigFlag("_devCycle.userIdSet")

  const { isTokenDead } = useHeartbeat(isAuthenticated && !SSEdied)
  useLogAttributes(userData)

  const dismissError = () => {
    setRequestResponse({})
  }

  const preventAppLoad = useMemo(() => state?.from?.pathname === "/download", [state?.from?.pathname])

  const handleTokenData = useCallback(
    (authResponse) => {
      logger.addAttribute("user", authResponse?.accessToken?.claims?.userInfo?.userId)
      logger.addAttribute("User Email", authResponse?.accessToken?.claims?.userInfo?.email)
      logger.addAttribute("User Role", authResponse?.accessToken?.claims?.userInfo?.role)
      window?.electronLogger?.loggedIn?.()
      const onElectron =
        navigator.userAgent?.toLowerCase().indexOf("electron") > -1 || window.electronWindow?.isElectron
      if (!onElectron) {
        logger.addAttribute("electronVersion", "web")
      }
      if (preventAppLoad) {
        setBasicAuthenticated(true)
        logger.debug("logged in to download page")
        return null
      }
      setIsAuthenticated(true)
      setToken(authResponse?.accessToken?.accessToken)
      setUserData({
        ...authResponse?.accessToken?.claims?.userInfo,
        role: authService.role,
      })
      logger.debug(
        `Login successful ${authResponse?.accessToken?.claims?.userInfo?.userId} with Session-ID ${authResponse?.accessToken?.claims?.userInfo?.marttixAgentSessionId}`
      )
      dismissError()
    },
    [preventAppLoad, setIsAuthenticated, setToken, setUserData, setBasicAuthenticated]
  )

  const setAuthData = useCallback(
    ({ token, user, sessionId, role }) => {
      setToken(() => token)
      setUserData(() => user)

      config.merge({
        accessToken: token,
        sessionId,
        agentId: user?.userId,
        role,
      })

      // This may not be correct anymore, we probably should not reconnect if sseHasDied
      SSE.reconnect()
    },
    [setToken, setUserData]
  )

  const _onOktaAuthenticationSuccess = useCallback(
    (authResponse) => {
      oktaAuth.handleLoginRedirect(authResponse)
      handleTokenData(authResponse)
    },
    [handleTokenData, oktaAuth]
  )

  const _onOktaAuthenticationError = useCallback(
    async (err) => {
      if (err instanceof OktaAppError) {
        showErrorToast(err.message, {
          closeOnClick: true,
        })
        await _logout()
        if (config.get("ui.reloadOktaError")) {
          location.reload()
        }
      }
    },
    [showErrorToast]
  )

  const logout = usePureCallback(async () => {
    try {
      showLoading()
      await _logout()
      await SSE.close()
      oktaAuth.tokenManager.clear()
      if (!userData?.isExternalIdp) {
        await oktaAuth.signOut()
      }
      logger.debug("Logout success")
    } catch (err) {
      logger.error(
        "An error occurred during logout",
        `status: ${err?.status} - message: ${err?.message}`,
        err?.base ?? err
      )
    } finally {
      hideLoading()
      authService.onLogout()
      setIsAuthenticated(false)
      setToken(initialState.token)
      setUserData(initialState.userData)
      navigate("/")
      navigate(0)
    }
  })

  const requestReset = async (email) => {
    try {
      await dataService.Auth.resetPassword(email)
      setRequestResponse({ success: true, message: `Reset password email sent to ${email}.` })
    } catch (err) {
      setRequestResponse({ error: err, message: err.message || "Reset password failed for unknown reason." })
    }
  }

  const enableMfa = async () => {
    try {
      await dataService.Auth.enableMfa()
      showInfoToast("MFA is enabled. Please sign in again to set it up!", {
        autoClose: false,
      })
      setHasOptedInMFA(true)
    } catch (err) {
      showErrorToast("Something went wrong when enabling MFA")
    }
  }

  useEffect(() => {
    if (!isAuthenticated || !userIdIsSet || isTokenDead) {
      return
    }
    SSE.open({ logger, config })
    setSSEopen(true)
    return () => {
      setSSEopen(false)
      SSE.abort()
    }
  }, [isAuthenticated, userIdIsSet, isTokenDead])

  useEffect(() => {
    if (!SSEopen) {
      return
    }

    const onLogout = () => setSSEdied(true)
    SSE.on("logout", onLogout)
    return () => {
      setSSEdied(false)
      SSE.off("logout", onLogout)
    }
  }, [SSEopen])

  useEffect(() => {
    authService.on(AUTH_SUCCESS, _onOktaAuthenticationSuccess)
    authService.on(AUTH_FAILURE, _onOktaAuthenticationError)
    authService.on(AUTH_DATA_REFRESHED, setAuthData)
    return () => {
      authService.off(AUTH_SUCCESS, _onOktaAuthenticationSuccess)
      authService.off(AUTH_FAILURE, _onOktaAuthenticationError)
      authService.off(AUTH_DATA_REFRESHED, setAuthData)
    }
  }, [_onOktaAuthenticationError, _onOktaAuthenticationSuccess, setAuthData])

  const { initialAuthChecked } = useOkta({
    isAuthenticated,
  })

  useEffect(() => {
    SSE.on("connect", setSSEConnected)
    return () => {
      SSE.off("connect", setSSEConnected)
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        basicAuthenticated,
        initialAuthChecked,
        logout,
        dismissError,
        requestReset,
        enableMfa,
        token,
        userData,
        requestResponse,
        SSEopen,
        SSEConnected,
        isTokenDead,
        hasOptedInMFA,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const AuthProvider = memo(_AuthProvider)

export const useAuth = createContextHook(AuthContext, "useAuth must be used within an AuthProvider")

export default AuthContext
