import React, { useCallback, useEffect, useRef } from "react"
import { toast } from "react-toastify"
import { useAlerts } from "contexts/Alerts"
import useAudio from "hooks/useAudio"
import CallNotification from "./CallNotification"
import { logger } from "utils/logger"

const toastConfig = {
  autoClose: false,
  closeButton: false,
  position: toast.POSITION.BOTTOM_RIGHT,
  closeOnClick: false,
  draggable: false,
  className: "w-[450px] right-[135px] !p-0 !m-0 !cursor-default",
  bodyClassName: "!p-0 !m-0",
}

const CALL_NOTIFICATION_TOAST_ID = "CALL_NOTIFICATION_TOAST"

export const useHTMLCallNotification = ({ ringingCall, onAccept: _onAccept, onAcknowledged, acknowledged }) => {
  const { showToast, updateToast, dismissToast } = useAlerts()
  const { play, stop, isPlaying } = useAudio({ loop: true })
  const toastIdRef = useRef(null)

  const stopAudio = useCallback(() => {
    if (isPlaying()) {
      stop()
      logger.debug("ringtone playback stopped")
    }
  }, [stop, isPlaying])

  const playAudio = useCallback(() => {
    if (!isPlaying()) {
      play()
      logger.debug("ringtone playback started")
    }
  }, [play, isPlaying])

  const onAccept = useCallback(
    (...params) => {
      stopAudio()
      _onAccept(...params)
      onAcknowledged()
    },
    [_onAccept, stopAudio, onAcknowledged]
  )

  useEffect(() => {
    if (acknowledged) {
      stopAudio()
    }
  }, [acknowledged, stopAudio])

  useEffect(() => {
    if (ringingCall) {
      playAudio()
      if (!toastIdRef.current) {
        toastIdRef.current = showToast(<CallNotification ringingCall={ringingCall} onAccept={onAccept} />, null, {
          ...toastConfig,
          toastId: CALL_NOTIFICATION_TOAST_ID,
        })
        return
      }
      updateToast(toastIdRef.current, {
        render: () => <CallNotification ringingCall={ringingCall} onAccept={onAccept} />,
      })
    } else if (toastIdRef.current) {
      stopAudio()
      dismissToast(toastIdRef.current)
      toastIdRef.current = null
    }
  }, [ringingCall, onAccept, dismissToast, updateToast, showToast, playAudio, stopAudio])

  return
}
