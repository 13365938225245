import React from "react"
import { useDuration } from "@cloudbreakus/ui-components"
import AnswerButton from "./AnswerButton"

const CallNotification = ({ ringingCall, onAccept }) => {
  const { duration } = useDuration(ringingCall?.interaction?.startTime)

  const unitId = ringingCall?.interaction?.customerPhoneNumber || ringingCall?.interaction?.unitId
  const interactionId = ringingCall?.interaction?.name
  const queue = ringingCall?.queue?.name
  const channel = ringingCall?.interaction?.channel

  return (
    <div className="flex flex-row text-black">
      <div className="flex flex-col w-3/4 py-2 mx-3 text-left">
        <h2 className="pb-2 text-lg font-bold capitalize" role="alert" aria-live="assertive">
          {channel ? `Incoming ${channel} Call` : "Incoming Call"}
        </h2>
        <p>Unit ID: {unitId}</p>
        <p>Interaction ID: {interactionId}</p>
        <p>Queue: {queue}</p>
        <p>Duration: {duration}</p>
      </div>
      <div className={"flex flex-col justify-center items-center w-1/4 border-l border-gray-200"}>
        <AnswerButton channel={channel} onClick={() => onAccept?.(ringingCall)} focus />
      </div>
    </div>
  )
}

export default CallNotification
