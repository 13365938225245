import { useCallback, useState, useEffect } from "react"
import { useBrowserCallNotification } from "./useBrowser"
import { useHTMLCallNotification } from "./useHTML"
import { useOSCallNotification } from "./useOS"

export const useCallNotifications = ({ ringingCall, onAccept }) => {
  const [acknowledged, setAcknowledged] = useState(false)

  const onAcknowledged = useCallback(() => setAcknowledged(true), [])

  useEffect(() => {
    if (!ringingCall) {
      setAcknowledged(false)
    }
  }, [ringingCall])

  useBrowserCallNotification({ ringingCall, acknowledged, onAccept, onAcknowledged })
  useHTMLCallNotification({ ringingCall, acknowledged, onAccept, onAcknowledged })
  useOSCallNotification({ ringingCall, acknowledged, onAccept, onAcknowledged })

  return
}
